import {Link, Outlet} from "react-router-dom";
import styles from './RodoIndex.module.scss'
const RodoIndex = () => {
    return(
        <div className={styles.rodoIndex}>
            <div className={styles.container}>
                <nav className={styles.topNav}>
                    <Link to="/rodo/obowiazek-informacyjny" className={styles.link}>
                        Obowiązek informacyjny
                    </Link>
                    <Link to="/rodo/prawo-dostepu" className={styles.link}>
                        Prawo dostępu na strone
                    </Link>
                </nav>
                <h2>Obowiązek infomacyjny, Prawo dostępu na strone</h2>
                <Outlet />
            </div>
        </div>
    )
}

export default RodoIndex