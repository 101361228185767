import styles from './Summary.module.scss'
import CarConfig from "../../config/CarConfig";
import { BsFillSendFill } from "react-icons/bs";
import {useState} from "react";
import {Formik} from 'formik'
import { useNavigate  } from "react-router-dom";
import sendMail from "../../utils/sendMail";
const Summary = ({isActive, setActiveIndex}) => {
    const navigate = useNavigate();
    const getCarStorage = JSON.parse(localStorage.getItem('car'))
    const basicStorage = JSON.parse(localStorage.getItem('basicInformation'))
    const detailsStorage = JSON.parse(localStorage.getItem('detailsInformation'))
    const [loading, setLoading] = useState(false)
    const Element = ({name, value}) => {
        return(
            <h4 className={styles.element}>{name}: <span>{value}</span></h4>
        )
    }

    const BasicSummary = ({title, buttonTitle, index}) => {

        return(
            <div className={styles.summaryBox}>
                <div className={styles.top}>
                    <h3 className={styles.summaryBoxTitle}>{title}</h3>
                    <Element name="Imię" value={basicStorage.name} />
                    <Element name="Nazwisko" value={basicStorage.surname} />
                    <Element name="Telefon" value={basicStorage.phone} />
                    <Element name="Email" value={basicStorage.email} />
                </div>
                <div className={styles.backButtonWrapper}>
                    <button className={styles.backButton} onClick={() => setActiveIndex(index)}>Wróć do {buttonTitle}</button>
                </div>
            </div>
        )
    }

    const GetCarSummary = ({title, buttonTitle, index}) => {
        const drive  = CarConfig.drive
        const transmission  = CarConfig.transmission
        const fuelType = CarConfig.fuel_type

        return(
            <div className={styles.summaryBox}>
                <div className={styles.top}>
                    <h3 className={styles.summaryBoxTitle}>{title}</h3>
                    <Element name="Marka" value={getCarStorage.make} />
                    <Element name="Model" value={getCarStorage.model} />
                    <Element name="Rok" value={getCarStorage.year} />
                    <Element name="Napęd" value={drive[getCarStorage.drive]} />
                    <Element name="Skrzynia biegów" value={transmission[getCarStorage.transmission]} />
                    <Element name="Paliwo" value={fuelType[getCarStorage.fuel_type]} />
                </div>
                <div className={styles.backButtonWrapper}>
                    <button className={styles.backButton} onClick={() => setActiveIndex(index)}>Wróć do {buttonTitle}</button>
                </div>
            </div>
        )
    }

    const GetDetails = ({title, buttonTitle, index}) => {
        return(
            <div className={styles.summaryBox}>
                <div className={styles.top}>
                    <h3 className={styles.summaryBoxTitle}>{title}</h3>
                    <Element name="Miejsce wykonywania usługi" value={detailsStorage.place} />
                    <Element name="Adres" value={detailsStorage.address} />
                    <Element name="Typ wykonywanej usługi" value={detailsStorage.service} />
                    <Element name="Usługi" value={detailsStorage.mechanic.map(el => `${el} ,`)} />
                    <Element name="Własne części" value={detailsStorage.ownItems} />
                </div>
                <div className={styles.backButtonWrapper}>
                    <button className={styles.backButton} onClick={() => setActiveIndex(index)}>Wróć do {buttonTitle}</button>
                </div>
            </div>
        )
    }

    const handleSubmit = async () => {
        setLoading(true)
        if(await sendMail() === 200){
            setLoading(false)
            navigate('/kontakt/sukces')
        }
        else{
            setLoading(false)
            navigate('/kontakt/blad')
        }
    }


    return(
        <>
            {isActive &&
            <div className={styles.summary}>
                <h3 className={styles.summaryTitle}>Podsumowanie (4/4)</h3>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={{}}
                >
                    {(props) => (
                        <form onSubmit={props.handleSubmit}>
                            <div className={styles.summaryBoxWrapper}>
                                <BasicSummary title="Podstawowe informacje" buttonTitle="podstawowych informacji" index={0}/>
                                <GetCarSummary title="Wyszukaj swój samochód" buttonTitle="wyszukiwania swojego auta" index={1}/>
                                <GetDetails title="Informacje szczegółowe" buttonTitle="szczegółowych informacji" index={2} />
                            </div>
                            <p className={styles.sendInformation}>
                                Administratorem Pani/Pana danych osobowych jest Krzysztof Juczyński, prowadzący działalność przy
                                ul. Armii Krajowej 44/13, 94-046 Łódź, NIP 7272870919
                                Wszelkie pytania dotyczące przetwarzania Pani/Pana danych osobowych należy kierować na adres
                                mechamosta@gmail.com lub na podany powyżej adres poczty tradycyjnej. Pani/Pana dane będą
                                przetwarzane w celu obsługi Pani/Pana zapytania przez okres niezbędny dla realizacji tego celu.
                                Przetwarzanie Pani/Pana danych osobowych będzie odbywać się na podstawie art. 6 pkt 1 lit. f)
                                Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                                ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego
                                przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
                                danych) (Dz. Urz. UE L nr 119 str. 1) („RODO”) w prawnie usprawiedliwionym interesie
                                Administratora Danych jakim jest obsługa Pani/Pana zapytania zgodnie z wybranym tematem oraz
                                ochrona przed ewentualnymi roszczeniami na drodze cywilnej związanymi z realizacją Pani/Pana
                                zapytania.
                                Dane będą przechowywane nie dłużej niż jest to konieczne do udzielenia odpowiedzi, a po tym czasie
                                mogą być przetwarzane do czasu upływu przedawnienia ewentualnych roszczeń.
                                Administrator nie stosuje zautomatyzowanego podejmowania decyzji, w tym profilowania.
                                Przysługuje Pani/Panu prawo dostępu do treści swoich danych oraz prawo ich poprawiania,
                                sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do
                                wniesienia sprzeciwu wobec przetwarzania danych.
                                Ponadto ma Pani/Pan prawo do wniesienia skargi do organu nadzorczego, którym jest Prezes Urzędu
                                Ochrony Danych Osobowych, gdy uzna Pani/Pan, iż przetwarzanie danych osobowych dotyczących
                                Pani/Pana narusza przepisy RODO.
                                Podanie danych osobowych jest dobrowolne ale niezbędne do przetworzenia Pani/Pana zapytania i
                                udzielenia odpowiedzi.
                            </p>
                            <div className={styles.sendFormWrapper}>
                                <button type="submit" className={styles.sendForm} disabled={loading}>{loading ? 'Wysyłam' : <>Wyślij formularz <BsFillSendFill className={styles.icon}/></>}</button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
            }

        </>
    )
}

export default Summary;