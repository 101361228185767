const InformationObligation = () => {
    return(
        <div>
            <p>
                <strong>
                    W związku z rozpoczęciem obowiązywania z dniem 25 maja 2018 r. Rozporządzenia
                    Parlamentu Europejskiego Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                    ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie
                    swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne
                    rozporządzenie o ochronie danych), informuję Panią/Pana, że:
                </strong>
            </p>
            <p>
                1. Administratorem Pani/Pana danych osobowych jest Krzysztof Juczyński, prowadzący
                działalność przy ul. Armii Krajowej 44/13, 94-046 Łódź NIP 7272870919
            </p>
            <p>
                2. Z Administratorem danych osobowych można skontaktować się pod adresem mailowym
                mechamosta@gmail.com
            </p>
            <p>
                3. Pani/Pana dane osobowe przetwarzane są w celu realizacji usług związanych z
                mechaniką pojazdową na podstawie:
            </p>
            <ul>
                <li>- Art. 6 ust. 1 lit. b w celu wykonania tych usług;</li>
                <li>- Art. 6 ust. 1 lit. f RODO w celu realizacji prawnie uzasadnionych interesów Administratora w
                    szczególności telefonicznego potwierdzenia lub odwołania wizyty.</li>
                <li>
                    - Art. 6 ust. 1 lit. c RODO w zw. z art. 74 ust. 2 ustawy z dnia 29 września 1994 r. o
                    rachunkowości w celu przetwarzania dokumentacji księgowo – podatkowej w tym: prowadzenie
                    ksiąg rachunkowych, wystawianie rachunków za wykonane usługi co może wiązać się z
                    koniecznością przetwarzania danych osobowych.
                </li>
                <li>
                    - Art. 6 ust. 1 lit. f RODO w celu dochodzenia roszczeń względem Administratora oraz
                    dochodzenia i ochrony przed roszczeniami Administratora, przy czym uzasadniony interes
                    Administratora oraz Pani/Pana wynikają z innych przepisów prawa (m.in. kodeks cywilny);
                </li>
            </ul>
            <p>
                4. Podanie przez Panią/Pana danych osobowych jest wymogiem ustawowym. Jest
                Pani/Pan zobowiązana/y do ich podania. Konsekwencją niepodania danych osobowych
                będzie brak możliwości umówienia mobilnej wizyty. Konsekwencją niepodania danych
                będzie brak możliwości wykonania usług. Podanie numeru telefonu, adresu e mail jest
                dobrowolne a jego niepodanie będzie skutkowało brakiem możliwości odwołania wizyty
                w sytuacjach tego wymagających;
            </p>
            <p>
                5. Pani/Pana dane osobowe przetwarzane są również w celu marketingowym – tylko na
                podstawie wyrażonej przez Panią/Pana dodatkowej zgody. Podstawą prawną
                przetwarzania danych jest art. 6 ust. 1 lit. a) ogólnego rozporządzenia o ochronie danych
                (zgoda osoby, której dane dotyczą). Wyrażenie zgody na przetwarzanie Pani/Pana
                danych jest dobrowolne. Niewyrażenie zgody nie ma wpływu na realizację usług i ich
                jakość. Ma Pani/Pan prawo do cofnięcia zgody w dowolnym momencie bez wpływu na
                zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej
                cofnięciem. Zgodę może Pani/Pan odwołać w formie pisemnej wysłanej na adres
                siedziby Administratora lub mu bezpośrednio doręczonej. Podanie przez Panią/Pana
                danych jest dobrowolne. Konsekwencją niepodania danych będzie brak możliwości
                otrzymywania przez Panią/Pana informacji handlowych.
            </p>
            <p>
                6. Posiada Pani/Pan prawo dostępu do treści swoich danych i ich sprostowania, w tym
                poprawiania i uzupełniania, usunięcia lub ograniczenia przetwarzania oraz prawo
                wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych;
            </p>
            <p>
                7. Dane będą przechowywane przez wymagany przepisami prawa okres przechowywania
                dokumentacji dotyczącej usługi (co najmniej 6 lat) i dokumentacji księgowo podatkowej
                (5 lat) oraz okres przedawnienia roszczeń wynikający z kodeksu cywilnego; w
                przypadku przetwarzania w celu marketingowym do momentu odwołania przez
                Panią/Pana zgody;
            </p>
            <p>
                8. Odbiorcami Pani/Pana danych osobowych mogą być podmioty przetwarzające dane
                osobowe na zlecenie Administratora: dostawcy usług IT, księgowych, doradczych,
                kurierskich i pocztowych, medycznych;
            </p>
            <p>
                9. Pani/Pana dane osobowe nie będą przetwarzane w sposób zautomatyzowany i nie będą
                poddawane profilowaniu; w trakcie przetwarzania danych osobowych nie dochodzi do
                wyłącznie zautomatyzowanego podejmowania decyzji ani do profilowania, o których
                mowa w art. 22 ust. 1 i 4 RODO. Oznacza to, że wobec osoby, której dane dotyczą, żadne
                decyzje nie będą zapadały wyłącznie automatycznie oraz że nie buduje się żadnych
                profili takich osób.
            </p>
            <p>
                10. Pani/Pana dane będą udostępniane podmiotom zaufanym na zasadach określnych w
                polityce prywatności i polityce cookies.
            </p>
        </div>
    )
}

export default InformationObligation