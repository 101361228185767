export default {
    fuel_type : {
        gas: "Benzyna",
        diesel: 'Diesel',
        electricity: 'Elektryk'
    },
    transmission: {
        m: 'Manualna',
        a: 'Automatyczna'
    },
    drive: {
        fwd: 'Na przód',
        rwd: 'Na tył',
        awd: 'Na wszystkie'
    }

};