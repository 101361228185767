import styles from './WindowPrivacyPolicy.module.scss'
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
const WindowPrivacyPolicy = () => {
    const [windowVisible, setWindowVisible] = useState(false)

    useEffect(() => {
        if(localStorage.getItem('mechamosta-cookies')){
            setWindowVisible(false)
        }
        else{
            setWindowVisible(true)
        }
    }, []);

    const acceptCookies = () => {
        localStorage.setItem('mechamosta-cookies', true)
        setWindowVisible(false)
    }

    return(
        <>
            {windowVisible &&
                <div className={styles.windowPrivacyPolicy}>
                    <h5 className={styles.title}>Polityka prywatności</h5>
                    <p className={styles.text}>Strona wykorzystuje pliki cookies w celu prawidłowego działania strony, korzystania z narzędzi analitycznych i marketingowych oraz zapewniania funkcji społecznościowych. Szczegóły znajdziesz w <Link to="/polityka-prywatnosci">polityce prywatności.</Link></p>
                    <button onClick={acceptCookies} className={styles.acceptButton}>Akceptuje</button>
                </div>
            }
        </>
    )
}

export default WindowPrivacyPolicy;